.header, h1, h2 {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;     /* Center content vertically */
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 28px;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
}