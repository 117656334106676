.experience-row {
    display: flex;
    justify-content: center; /* Spreads out the items */
    align-items: center; /* Vertically centers the items */
    flex-wrap: wrap; /* Wraps items if screen is too small */
    gap: 20px; /* Adds space between the items */
    margin: 20px 0;
  }
  
  .experience-row img {
    width: 200px;
    height: 100px;
    transition: transform 0.3s; /* Optional hover effect */
    opacity: 0.5; /* Set initial opacity */
}
  
  .experience-row img:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
    opacity: 1;
  }
  