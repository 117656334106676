.navbar {
    display: flex;
    justify-content: space-between;
    color: var(--color-text);
    margin: 2rem 2rem;
    cursor: pointer;
    padding: 2rem 6rem;
}

.navbar a
{
    /* margin: 0 1rem; */
    text-decoration: none;
    transition: transform 0.2s;
}

.navbar a :hover
{
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
}
