.skill {
  padding: 20px;
}

.skill h2 {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-family: var(--font-family);
  color: var(--color-text);
}

.skill-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.skill-item {
  flex: 1 1 150px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-item img,
.skill-row .icon {
  width: 36px; /* Adjust as needed */
  height: 36px; /* Adjust as needed */
  opacity: 0.8; /* Slightly greyed-out */
  transition: opacity 0.3s ease; /* Smooth hover effect */
  object-fit: contain;

}

.skill-item img {
  width: 36px;
  height: 36px;
  object-fit: contain;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.skill-item img:hover {
  opacity: 1;
}
