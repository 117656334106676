* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clop: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 700px){
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media (max-width: 768px) {
  .navbar {
      flex-direction: center; /* Stack links vertically */
      padding: 2rem;         /* Reduce padding */
      align-items: flex-start; /* Align items to the start */
  }

  .navbar a {
      margin: 0.5rem 0;      /* Space links vertically */
      font-size: 16px;       /* Slightly reduce font size */
  }
}
@media screen and (max-width: 550){
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}